/* src/SignaturePad.css */
.signature-container {
    display: inline-block;
    text-align: left;
}

.signature-container button {
    margin: 5px;
}

.sigCanvas {
    border: 2px solid #000;
}
